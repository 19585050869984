import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow-circle-light.inline.svg'

const StyledBlogGridPost = styled(motion.div)``

const Thumbnail = styled(Plaatjie)`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
`

const Excerpt = styled.div`
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, #263280 0%, rgba(38, 50, 128, 0) 75.32%);
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transform: translateZ(2px);

  & svg {
    min-width: 30px;
    min-height: 30px;
  }
`

const H2 = styled.h2`
  color: ${({ theme }) => theme.color.light};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;

  font-family: ${({ theme }) => theme.font.family.primary};

  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.small};
    line-height: ${({ theme }) => theme.font.size.medium};
  }
`

const ReadMore = styled.span`
  color: ${({ theme }) => theme.color.primary};

  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.small};
    line-height: ${({ theme }) => theme.font.size.medium};
  }
`

interface PostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      excerpt: string
      thumbnail: any
    }
  }
  index: number
  currentIndex: number
  setCurrentIndex: (index: number) => void
}

const Post: React.FC<PostProps> = ({
  node,
  blogFields,
  index,
  setCurrentIndex,
  currentIndex,
}) => {
  const hovered = currentIndex === index

  return (
    <StyledBlogGridPost
      className="position-relative overflow-hidden"
      onHoverStart={() => setCurrentIndex(index)}
      onHoverEnd={() => setCurrentIndex(-1)}
    >
      <NavLink to={node.uri || '#'}>
        <motion.div
          initial={{ scale: 0 }}
          animate={hovered ? { scale: 1.1 } : { scale: 1 }}
        >
          <Thumbnail image={node.recap.thumbnail} alt="" objectFit="cover" />
        </motion.div>
        <Excerpt className="position-absolute py-3 px-2 px-sm-4 h-100 d-flex flex-column justify-content-end">
          <div className="d-flex align-items-center">
            <H2 className="mb-0">{node.title}</H2>
            <motion.div animate={hovered ? { x: 5 } : { x: 0 }}>
              <Arrow className="ms-3" />
            </motion.div>
          </div>
        </Excerpt>
      </NavLink>
    </StyledBlogGridPost>
  )
}

export default Post
