import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { BlogBinkFilter, useBlogBink } from '@ubo/blog-bink'

// Images
import Checkmark from 'img/checkmark.inline.svg'

const VacanciesFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-family: ${({ theme }) => theme.font.family.secondary};
  background-color: ${({ theme }) => theme.color.light};
  color: #cfcfcf;
  border-radius: 100px;
  margin-right: 20px;
  padding: 7px 35px;
  min-width: 134px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  ${(props) =>
    props.selected
      ? css`
          color: ${({ theme }) => theme.color.secondary};
        `
      : css`
          color: ${({ theme }) => theme.color.secondary};
        `}
`

const SVGWrapper = styled.div<{
  currentHover?: boolean
  isActive: boolean
  clearable?: boolean
}>`
  right: 5px;
  top: 5px;

  ${(props) =>
    props.currentHover &&
    css`
      opacity: 0.25;
    `}

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}

    ${(props) =>
    props.isActive &&
    props.currentHover &&
    props.clearable &&
    css`
      & svg circle {
        fill: tomato !important;
      }
    `}
`

interface FiltersProps {}

interface FilterProps {
  node: {
    id: string
    name: string
  }
}

const Filters: React.FC<FiltersProps> = () => {
  const blogBink = useBlogBink()

  const [hovered, setHovered] = useState<number | string>(-1)

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-sm-start mb-5 pb-lg-5">
      <motion.div
        onHoverStart={() => setHovered('all')}
        onHoverEnd={() => setHovered(-1)}
        onClick={() => blogBink.reset()}
      >
        <VacanciesFilter
          key="all-categories"
          id="all-categories"
          selected={blogBink.selectedFilters.length === 0}
          className="position-relative mr-3 mb-4"
        >
          <div className="d-flex align-items-center justify-content-center">
            <span>All</span>

            {(blogBink.selectedFilters.length === 0 || hovered === 'all') && (
              <SVGWrapper
                className="position-absolute"
                isActive={blogBink.selectedFilters.length === 0}
                currentHover={hovered === 'all'}
              >
                <Checkmark />
              </SVGWrapper>
            )}
          </div>
        </VacanciesFilter>
      </motion.div>

      {blogBink.categories.map((category: FilterProps, index: number) => {
        return (
          <motion.div
            onHoverStart={() => setHovered(index)}
            onHoverEnd={() => setHovered(-1)}
            key={category.node.id}
          >
            <VacanciesFilter
              id={category.node.id}
              selected={blogBink.isSelected(category.node.id)}
              className="position-relative mr-3 mb-4"
            >
              <div className="d-flex align-items-center justify-content-center">
                <span>{category.node.name}</span>

                {(blogBink.selectedFilters.includes(category.node.id) ||
                  hovered === index) && (
                  <SVGWrapper
                    className="position-absolute"
                    currentHover={hovered === index}
                    isActive={blogBink.selectedFilters.includes(
                      category.node.id
                    )}
                    clearable
                  >
                    <Checkmark />
                  </SVGWrapper>
                )}
              </div>
            </VacanciesFilter>
          </motion.div>
        )
      })}
    </div>
  )
}

export default Filters
