import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import Filters from 'components/elements/Misc/Filters'

// Layout
import Grid from './Layout/Grid'

// Interface
import { PostsProps } from './PostsShell'

const Section = styled.section`
  z-index: 2;
`

const Blog: React.FC<PostsProps> = ({ fields }) => {
  const { allWpPost, allWpCategory } =
    useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
      query blogQuery {
        allWpPost {
          edges {
            node {
              ...generalPostFragment
            }
          }
        }
        allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `)

  const posts: unknown = allWpPost.edges

  return (
    <Section className="position-relative">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="posts-overview"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <Filters />
            </div>
            <Grid fields={fields} />
          </div>
        </div>
      </BlogBink>
    </Section>
  )
}

export default Blog
